<template>
  <div style="overflow: hidden" id="box">
    <img class="bg" src="@/assets/lottery/tjf_cj_cjxq_bg_img-min.png" alt="" />
    <div class="lottery">
      <van-swipe class="lottery_img" :autoplay="3000" :touchable="false" loop :show-indicators="false">
        <van-swipe-item
          v-for="(item, index) in lotteryItem.prizes"
          :key="index"
        >
          <img :src="item.image" alt="">
        </van-swipe-item>
      </van-swipe>
      <!-- 等奖 -->
      <div class="award">
        <div
          class="award_item"
          :class="computedData(item.level)"
          v-for="(item, index) in lotteryItem.prizes"
          :key="index"
        >
          <span
            >{{
              item.level == 1 ? "一" : item.level == 2 ? "二" : "三"
            }}等奖</span
          >
          <div>{{ item.title }}</div>
        </div>
      </div>
      <div class="lottery_draw_time">
        距离开奖仅剩
        <uni-countdown
          :showDay="false"
          :showColon="false"
          :hour="lotteryItem.hour"
          :minute="lotteryItem.min"
          splitorColor="#F93F34"
          backgroundColor="rgba(255, 230, 147, .6)"
          color="#F93F34"
        ></uni-countdown>
      </div>

      <!-- 抽奖按钮 -->
      <!-- 参加 -->
      <template v-if="lotteryItem.is_join && lotteryItem.is_open == false">
        <div class="lottery_tips">
          <div class="lottery_tips_son">
            观看视频提升
            <span>{{ rate }}</span>
            倍中奖率
          </div>
          <img src="@/assets/lottery/tjf_cj_cjxq_zjl_bg_img.png" alt="" />
        </div>
        <div class="lottery_scroll">
          <div class="breath"></div>
          <div class="lottery_scroll_box" id="box_2" @click="_golink">
            <img src="@/assets/lottery/tjf_cj_cjxq_gksp_icon.png" alt="" />
            <span>去观看</span>
          </div>
        </div>
      </template>

      <!-- 未参加 -->
      <div
        class="lottery_status"
        @click="LotteryJoin"
        v-else-if="lotteryItem.is_join == false && lotteryItem.is_open == false"
      >
        <div class="breath"></div>
        <div class="lottery_status_box">立即抽奖</div>
      </div>

      <div class="lottery_status" v-else>
        <div
          class="lottery_status_box"
          style="
            background: linear-gradient(
              135deg,
              rgba(251, 92, 53, 0.6),
              rgba(249, 63, 52, 0.6)
            );
          "
        >
          已开奖
        </div>
      </div>

      <!-- 参与用户 -->
      <template v-if="userList.data">
        <div class="lottery_num">
          已有{{ userList.total }}人参与，
          <span @click="_golink">查看排行榜</span>
          <img src="@/assets/lottery/youjiantou_taojiefang.png" alt="" />
        </div>
        <div class="lottery_user">
          <img
            :src="item.avatar"
            v-for="(item, index) in userList.data"
            :key="index"
            alt=""
          />
        </div>
      </template>
    </div>
    <van-overlay :show="show" @click="show = false">
      <self-mask></self-mask>
    </van-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import {
  isMicroMessenger,
  isAndroid,
  isIOS,
  isMobile,
} from "@/utils/user-agent";
import { Overlay, Swipe, SwipeItem } from "vant";
import { LotteryGetJoinedUsers, LotteryItem, LotteryJoin } from "@/api/lottery";
import uniCountdown from "../bargain/uni-countdown.vue";
import SelfMask from "@/views/alipage/ToTaoBao";
Vue.use(Overlay).use(Swipe).use(SwipeItem);
export default {
  components: {
    uniCountdown,
    SelfMask,
  },

  data() {
    return {
      lotteryItem: {}, //详情
      userList: [], //用户列表-固定数量
      id: null, //抽奖id
      rate: null, //倍数
      show: false,
    };
  },

  computed: {
    computedData() {
      return (data) => {
        let map = {
          1: "award_first",
          2: "award_second",
          3: "award_third",
        };
        return map[data];
      };
    },
  },

  created() {
    this.id = this.$route.params.id;
    this.LotteryItem();
    this.LotteryGetJoinedUsers();
  },

  methods: {
    //抽奖详情
    LotteryItem() {
      LotteryItem({ id: this.id }).then((res) => {
        if (res.errCode === 0) {
          this.lotteryItem = res.data;
          this.rate = res.data.rate;

          //获取两个时间戳的差
          let timestamp = Math.round(new Date()); //当前时间
          //ios无法识别‘-’所以替换一下
          let timer = new Date(
            this.lotteryItem.end_at.replace(/-/g, "/")
          ).getTime(); //后端时间

          this.timediff((timer - timestamp) / 1000, this.lotteryItem);

          console.log(this.lotteryItem);
          if (this.lotteryItem.is_join && this.lotteryItem.is_open) {
            this.$router.push(`/lottery-award/${this.id}`);
          }
        }
      });
    },

    //转化时间
    timediff(total, data) {
      let day = parseInt(total / (24 * 60 * 60)); //计算整数天数
      let afterDay = total - day * 24 * 60 * 60; //取得算出天数后剩余的秒数
      let hour = parseInt(afterDay / (60 * 60)); //计算整数小时数
      let afterHour = afterDay - hour * 60 * 60; //取得算出小时数后剩余的秒数
      let min = afterHour / 60; //计算整数分
      let afterMin = parseInt(
        total - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60
      );
      //赋值
      (data.day = day),
        (data.hour = hour),
        (data.min = min),
        (data.afterMin = afterMin);
    },

    //获取抽奖参加的用户列表
    LotteryGetJoinedUsers() {
      let params = {
        id: this.id,
        page: 1,
        rows: 8,
      };
      LotteryGetJoinedUsers(params).then((res) => {
        if (res.errCode === 0) {
          this.userList = res.data;
        }
      });
    },

    //参加
    LotteryJoin() {
      LotteryJoin({
        id: this.id,
      })
        .then((res) => {
          if (res.errCode === 0) {
            this.LotteryItem();
            this.LotteryGetJoinedUsers();
            this.$router.push("/attention");
          }
        })
        .catch((err) => {});
    },

    //去下载
    _golink() {
      if (isIOS) {
        var ios_link =
          "https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745";
        window.location.href = ios_link;
        return "ios";
      } else {
        // 安卓微信浏览器需要在默认浏览器打开
        // if (isMicroMessenger) {
        //   this.show = true;
        //   return false;
        // }

        var andriod_link =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        // "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        window.location.href = andriod_link;
        return "andriod";
      }
    },
  },
};
</script>

<style lang="less">
page {
  background-color: #f7f7f7;
}

.bg {
  width: 100%;
  height: 267px;
  position: absolute;
}

// 抽奖
.lottery {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 10px;
  padding: 9px 9px 35px;
  box-sizing: border-box;
  width: 355px;
  background: #ffffff;
  border-radius: 10px;
  text-align: center;

  &_img {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }
  }

  .award {
    margin-top: 10px;

    &_first {
      background: linear-gradient(90deg, #ffeee7, #ffffff);

      span {
        color: #ea3d2b;
      }
    }

    &_second {
      background: linear-gradient(90deg, #edf0f8, #ffffff);

      span {
        color: #0185ca;
      }
    }

    &_third {
      background: linear-gradient(90deg, #fef0dc, #ffffff);

      span {
        color: #ff8400;
      }
    }

    &_item {
      margin-bottom: 8px;
      width: 337px;
      padding: 9px 0;
      border-radius: 17px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      display: flex;
      align-items: flex-start;

      span {
        white-space: nowrap;
        margin: 0 12px 0 13px;
      }

      div {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
    }
  }

  &_draw_time {
    display: flex;
    align-items: center;
    margin-top: 16px;
    height: 29px;
    padding: 0 18px;
    line-height: 29px;
    text-align: center;
    background: rgba(255, 230, 147, 0.6);
    border-radius: 15px;
    font-size: 14px;
    font-weight: 600;
    color: #cd764b;

    text {
      color: #f93f34;
    }
  }

  &_tips {
    margin-top: 7px;
    position: relative;

    &_son {
      width: 200px;
      height: 33px;
      line-height: 33px;
      text-align: center;
      background: #fff6e5;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 400;
      color: #c7612f;
      animation: upDown infinite 3.5s ease;

      text {
        color: #f65821;
      }
    }

    img {
      position: absolute;
      left: 50%;
      top: 34px;
      transform: translateX(-50%);
      width: 9px;
      height: 7px;
    }
  }

  &_scroll {
    height: 130px;
    margin-top: 8px;
    position: relative;
    white-space: nowrap;

    display: flex;
    position: relative;
    align-items: center;

    .across {
      width: 76px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &_box {
      width: 110px;
      height: 110px;
      background: linear-gradient(135deg, #fb5c35, #f93f34);
      font-size: 17px;
      font-weight: 600;
      border-radius: 50%;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        width: 25px;
        height: 25px;
        margin-bottom: 6px;
      }

      .timer {
        position: absolute;
        width: 40px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #fa4d34;
        border-radius: 12px;
        box-sizing: border-box;
        font-size: 11px;
        font-weight: 400;
        color: #fa5335;
        top: 6px;
        right: -19px;
      }
    }

    .wait {
      margin-left: 122.5px;
      background: linear-gradient(
        135deg,
        rgba(251, 92, 53, 0.6),
        rgba(249, 63, 52, 0.6)
      );
    }

    .breath {
      position: absolute;
      width: 110px;
      height: 110px;
      background: linear-gradient(
        135deg,
        rgba(251, 92, 53, 0.16),
        rgba(249, 63, 52, 0.16)
      );
      border-radius: 50%;
      animation: breath infinite 1.5s ease;
    }
  }

  &_status {
    margin-top: 48px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    position: relative;

    .breath {
      position: absolute;
      width: 110px;
      height: 110px;
      background: linear-gradient(
        135deg,
        rgba(251, 92, 53, 0.16),
        rgba(249, 63, 52, 0.16)
      );
      border-radius: 50%;
      animation: breath infinite 1.5s ease;
    }

    &_box {
      width: 110px;
      line-height: 110px;
      text-align: center;
      height: 110px;
      background: linear-gradient(135deg, #fb5c35, #f93f34);
      font-size: 17px;
      font-weight: 600;
      border-radius: 50%;
      color: #ffffff;
    }
  }

  &_num {
    margin-top: 23px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    color: #4f4f4f;

    span {
      color: #fa4f35;
    }

    img {
      margin-left: 5px;
      width: 6.5px;
      height: 11px;
    }
  }

  &_user {
    height: 35px;
    margin-top: 19px;
    display: flex;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* 心跳动画 */
@keyframes breath {
  0% {
    transform: scale(1.01);
  }

  30% {
    transform: scale(1.16);
  }

  100% {
    transform: scale(1.01);
  }
}

/* 心跳动画 */
@keyframes upDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
